<!--
 * @Author: 刘格优
 * @Date: 2019-12-27 11:08:39
 * @LastEditors  : 刘格优
 * @LastEditTime : 2019-12-31 17:49:42
 -->

<template>
  <div class="container">
    <div class="content">
      <div class="content">
        <div class="top">
          <p>使用指南</p>
          <p class="imglogo">
            <img src="@/assets/dr/round.png"
                 alt="">
            <span>上海东瑞</span>
          </p>
        </div>
        <ul class="ulbox">
          <li v-for="(item, index) in lists"
              :key="index"
              class="libox"
              @click="touse(item.url)">
            <span class="title">{{item.title}}</span>
            <span class="name">{{item.name}}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { wechatshare } from '@/lib/wechat_share'
export default {
  data () {
    return {
      lists: [
        {
          title: '黑科技展业工具——东瑞助手来啦！',
          name: '操作视频',
          url: 'https://mp.weixin.qq.com/s/JmKV2rmkD0pELlhsOugPAQ'
        },
        {
          title: '[使用指南]如何快速制作文章',
          name: '爆文制作',
          url: 'https://mp.weixin.qq.com/s/c7NpRQwhtllbEeBq4L7gGA'
        },
        {
          title: '[使用指南]怎样轻松获客',
          name: '获客工具',
          url: 'https://mp.weixin.qq.com/s/hzmb8t3wHaHsMMdS_k8ZdA'
        },
        {
          title: '[使用指南]如何知晓谁在看我',
          name: '谁在看我',
          url: 'https://mp.weixin.qq.com/s/LI6wo4W-vEku68N1hKwrAA'
        },
        {
          title: '[使用指南]如何快速确定准客户',
          name: '客户挖掘',
          url: 'https://mp.weixin.qq.com/s/2ajcwslsSoyVHigZ7lqGoQ'
        },
        {
          title: '[使用指南]谁咨询了你，一眼就知道',
          name: '咨询列表',
          url: 'https://mp.weixin.qq.com/s/nItEjqC2qpYPMyCWpFZ4Sg'
        },
        {
          title: '[使用指南]如何制作精美、吸引人的电子名片',
          name: 'AI名片',
          url: 'https://mp.weixin.qq.com/s/FxQdZPnjOKD4APZlL0_CyQ'
        }
      ]
    }
  },

  components: {},

  computed: {},
  mounted () {
    wechatshare('', '', '', '', '', '', true)
  },
  methods: {
    touse (url) {
      window.location.href = url
    }
  }
}

</script>
<style scoped>
.libox {
  display: flex;
  align-items: center;
  align-items: center;
  height: 2.2rem;
  padding: 0 0.3rem;
  border-bottom: 1px solid #eaeaea;
}
.title {
  width: 70%;
  padding: 0.2rem;
  font-size: 0.4rem;
}
.top {
  height: 2.5rem;
  padding: 0.4rem 0.3rem;
  border-bottom: 1px solid #eaeaea;
}
.top p {
  font-size: 0.5rem;
}
.name {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  width: 25%;
  height: 1.5rem;
  line-height: 1rem;
  background-color: #039e89;
  color: white;
  border-radius: 0.1rem;
  margin-left: 3%;
  font-size: 0.5rem;
}
.imglogo {
  margin-top: 0.3rem;
}
.imglogo img {
  width: 70px;
  height: 70px;
}
.imglogo span {
  margin-left: 0.2rem;
  position: relative;
  bottom: 20px;
  color: #039e89;
}
.ulbox{
  margin-bottom: .5rem
}
</style>
